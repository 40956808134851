import React from "react";
import Layout from "../../components/layout";
import { Container, H1, PrimaryButton } from "../../styles/ui";
import styled from "@emotion/styled";
import Contact from "../../components/contact";
import { StaticImage } from "gatsby-plugin-image";

const EddigiMunkaink: React.FC = () => {
  return (
    <Layout
      allowTransparentHeader={true}
      variant="weboldal"
      title="Referenciák"
      description="Weboldal Referenciák"
    >
      <Wrapper>
        <H1>
          Eddigi <span>munkáink</span>
        </H1>
        <br />
        <br />
        <Munka>
          <div>
            <StaticImage
              style={{ borderRadius: "20px", boxShadow: "var(--shadow)" }}
              imgStyle={{ borderRadius: "20px" }}
              src="../../images/weboldalak/fastrrr.jpg"
              alt="Fastrrr"
            />
          </div>
          <div>
            <h3>Fastrrr - Science Behind Sailing</h3>
            <p>
              Komplex webshop konfigurálható szoftver és hardver csomagokkal. A
              legújabb web development módszerek használata az elérések növelése
              érdekében.
            </p>
            <a href="https://fastrrr.com" target="_blank" rel="noreferrer">
              <PrimaryButton>MEGNÉZEM A WEBOLDALT</PrimaryButton>
            </a>
          </div>
        </Munka>
        <Munka>
          <div>
            <StaticImage
              style={{ borderRadius: "20px", boxShadow: "var(--shadow)" }}
              imgStyle={{ borderRadius: "20px" }}
              src="../../images/weboldalak/vulpex.jpg"
              alt="Vulpex Kft."
            />
          </div>
          <div>
            <h3>Vulpex Kft. - Webshop rebuild</h3>
            <p>Többnyelvű webshop Wordpress alapokon.</p>
            <a href="https://vulpexkft.hu" target="_blank" rel="noreferrer">
              <PrimaryButton>MEGNÉZEM A WEBOLDALT</PrimaryButton>
            </a>
          </div>
        </Munka>
      </Wrapper>
      <Contact variant="weboldal" />
    </Layout>
  );
};

export default EddigiMunkaink;

const Wrapper = styled(Container)`
  color: white;
  padding-top: 160px;
  padding-bottom: 96px;
`;

const Munka = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-bottom: 64px;
  h3 {
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 32px;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;
